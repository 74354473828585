import moment from 'moment'
import React from 'react'
import './App.css'
import UserDetails from './UserDetails'

class ProgramsStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {}
  render() {
    const { users, programs } = this.props
    let programsA = []
    programs.forEach((p) => {
      let progttemp = {}
      let epsTemp = []
      let pnbNote = 0
      let pcumul = 0
      p.episodes.forEach((e) => {
        let epTemp = {}
        let viewsT = []
        let nbNote = 0
        let cumul = 0
        users.forEach((u) => {
          let vt = u.views.find((v) => v.episodeId === e.id)
          if (vt && vt.note >= 0) {
            viewsT.push(vt)
            nbNote += 1
            cumul += vt.note + 1
            pnbNote += 1
            pcumul += vt.note + 1
          }
        })
        epTemp = { ...e, views: viewsT, nbNote, cumul }
        epsTemp.push(epTemp)
      })
      progttemp = { ...p, episodes: epsTemp, pnbNote, pcumul }
      programsA.push(progttemp)
    })

    return (
      <div className="globalContainer">
        {programsA.map((p) => (
          <div>
            <h3>
              {p.title.replace('$', '')} : {Math.round((p.pcumul / p.pnbNote) * 10) / 10}⭐ ({p.pnbNote} notes)
            </h3>

            <div className="tableContainer">
              <table style={{ marginBottom: '30px' }}>
                <tr>
                  <td>Episode</td>
                  {p.episodes.map((e) =>
                    e.nbNote === 0 ? null : (
                      <td>
                        {e.order} - {e.title}
                      </td>
                    ),
                  )}
                </tr>
                <tr>
                  <td>Note moyenne</td>
                  {p.episodes.map((e) =>
                    e.nbNote === 0 ? null : <td>{Math.round((e.cumul / e.nbNote) * 10) / 10}⭐</td>,
                  )}
                </tr>
                <tr>
                  <td>Nb note</td>
                  {p.episodes.map((e) => (e.nbNote === 0 ? null : <td>{e.nbNote}</td>))}
                </tr>
              </table>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default ProgramsStats
