import moment from 'moment'
import React from 'react'
import './App.css'
import UserDetails from './UserDetails'

class UsersRecents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {}
  render() {
    const { users, programs } = this.props
    let currentDate = moment()
    let users1D = []
    let users3D = []
    let users7D = []
    let usersOld = []
    let usersNotStarted = []
    console.log(users.length)
    users.forEach((u) => {
      let mostRecent = undefined
      u.views.forEach((v) => {
        if (!mostRecent) mostRecent = moment(v.date)
        if (mostRecent < moment(v.date)) mostRecent = moment(v.date)
      })
      console.log(u.email, mostRecent)
      if (mostRecent === undefined) {
        usersNotStarted.push(u)
      } else if (currentDate.diff(mostRecent, 'days') <= 1) {
        users1D.push(u)
      } else if (currentDate.diff(mostRecent, 'days') <= 3) {
        users3D.push(u)
      } else if (currentDate.diff(mostRecent, 'days') <= 7) {
        users7D.push(u)
      } else {
        usersOld.push(u)
      }
    })
    console.log(users1D.length)
    console.log(users3D.length)
    console.log(users7D.length)
    console.log(usersOld.length)
    console.log(usersNotStarted.length)
    console.log(users1D.length + users3D.length + users7D.length + usersOld.length + usersNotStarted.length)
    return (
      <div className="globalContainer">
        <div className="categContainer" style={{ borderColor: 'green' }}>
          <h3 style={{ color: 'green' }}>Utilisateurs Actifs ce dernier jour</h3>
          {users1D.map((u) => (
            <UserDetails user={u} programs={programs} />
          ))}
        </div>
        <div className="categContainer" style={{ borderColor: 'yellow' }}>
          <h3 style={{ color: 'yellow' }}>Utilisateurs Actifs ces 3 derniers jours</h3>
          {users3D.map((u) => (
            <UserDetails user={u} programs={programs} />
          ))}
        </div>
        <div className="categContainer" style={{ borderColor: 'orange' }}>
          <h3 style={{ color: 'orange' }}>Utilisateurs Actifs ces 7 derniers jours</h3>
          {users7D.map((u) => (
            <UserDetails user={u} programs={programs} />
          ))}
        </div>
        <div className="categContainer" style={{ borderColor: 'red' }}>
          <h3 style={{ color: 'red' }}>Utilisateurs inactifs ces 7 derniers jours</h3>
          {usersOld.map((u) => (
            <UserDetails user={u} programs={programs} />
          ))}
        </div>
        <div className="categContainer" style={{ borderColor: 'grey' }}>
          <h3 style={{ color: 'grey' }}>Utilisateurs ayant un programme mais n'ayant pas commencé !!!</h3>
          {usersNotStarted.map((u) => (
            <UserDetails user={u} programs={programs} />
          ))}
        </div>
      </div>
    )
  }
}

export default UsersRecents
