import axios from 'axios'

const initInterceptorRequest = (client) => {
  client.interceptors.request.use((requestConfig) => {
    requestConfig.headers.authorization = 'DatasAuth2020OSART'
    return requestConfig
  })

  return client
}

const httpClient = initInterceptorRequest(axios.create({ baseURL: 'https://api.fasterclass.fr', timeout: 5000 }))

export default httpClient
