import React from 'react'
import logo from './logo.svg'
import './App.css'
import API from './axios'
import UsersRecents from './UsersRecents'
import UserDetails from './UserDetails'
import ProgramsStats from './ProgramsStats'
import md5 from 'md5'

class DataWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      datas: {
        programs: [],
        users: [],
      },
      errored: false,
      pwded: false, //false
      login: '',
      password: '',
      filtered: 'lastView',
    }
  }
  async loadDatas() {
    //loadData
    const request = await API.get('/datas/user/all')
    this.setState(
      {
        datas: request.data,
      },
      () => setTimeout(() => this.setState({ loaded: true }), 2000),
    )
  }
  onLogin = () => {
    console.log(md5('saltjfvoiezjfoij' + this.state.login + 'fkzhjefiuzhefiuz'))
    if (
      md5('saltjfvoiezjfoij' + this.state.password + 'fkzhjefiuzhefiuz') !== '361bfe0b861145a6342784f31a3a9460' ||
      md5('saltjfvoiezjfoij' + this.state.login + 'fkzhjefiuzhefiuz') !== '2990e1f1d6e91d255cc96b3916a448fd'
    ) {
      this.setState({ errored: true })
    } else {
      this.setState({ errored: false, login: '', password: '', pwded: true }, () => this.loadDatas())
    }
  }
  render() {
    if (!this.state.pwded)
      return (
        <div className="App">
          <header className="App-header">
            <h1>Suivi des utilisateurs payants</h1>
            <p>Login</p>
            <input
              type="text"
              value={this.state.login}
              onChange={(event) => this.setState({ login: event.target.value })}
            />
            <p>Password</p>
            <input
              type="password"
              value={this.state.password}
              onChange={(event) => this.setState({ password: event.target.value })}
            />
            <p style={{ color: 'red' }}>{this.state.errored && 'Wrong logins'}</p>
            <input type="button" value="Se connecter" onClick={this.onLogin} />
          </header>
        </div>
      )
    if (!this.state.loaded)
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Chargement des données...</p>
          </header>
        </div>
      )
    if (this.state.filtered === 'lastView')
      return (
        <div className="App">
          <header className="App-header">
            <h1>Suivi des utilisateurs payants</h1>
            {this.getFilters()}
            <UsersRecents users={this.state.datas.users} programs={this.state.datas.programs} />
          </header>
        </div>
      )
    if (this.state.filtered === 'brute')
      return (
        <div className="App">
          <header className="App-header">
            <h1>Suivi des utilisateurs payants</h1>
            {this.getFilters()}
            <div className="userNoAddContainer">
              {this.state.datas.users.map((u) => (
                <UserDetails user={u} programs={this.state.datas.programs} />
              ))}
            </div>
          </header>
        </div>
      )
    if (this.state.filtered === 'dataPrograms')
      return (
        <div className="App">
          <header className="App-header">
            <h1>Suivi des utilisateurs payants</h1>
            {this.getFilters()}
            <div className="userNoAddContainer">
              <ProgramsStats users={this.state.datas.users} programs={this.state.datas.programs} />
            </div>
          </header>
        </div>
      )
    return (
      <div className="App">
        <header className="App-header">
          <h1>Suivi des utilisateurs payants</h1>
          {this.getFilters()}
          <UsersRecents users={this.state.datas.users} programs={this.state.datas.programs} />
        </header>
      </div>
    )
  }

  getFilters = () => {
    let { filtered } = this.state
    if (filtered === 'lastView')
      return (
        <p className="filters">
          Ordre : [A regardé récemment] |{' '}
          <a onClick={() => this.setState({ filtered: 'brute' })}>Inscription récente</a> |{' '}
          <a onClick={() => this.setState({ filtered: 'dataPrograms' })}>Notes programmes</a>
        </p>
      )
    if (filtered === 'brute')
      return (
        <p className="filters">
          Ordre : <a onClick={() => this.setState({ filtered: 'lastView' })}>A regardé récemment</a> | [Inscription
          récente] | <a onClick={() => this.setState({ filtered: 'dataPrograms' })}>Notes programmes</a>
        </p>
      )
    if (filtered === 'dataPrograms')
      return (
        <p className="filters">
          Ordre : <a onClick={() => this.setState({ filtered: 'lastView' })}>A regardé récemment</a> |{' '}
          <a onClick={() => this.setState({ filtered: 'brute' })}>Inscription récente</a> | [Notes programmes]
        </p>
      )
  }
}

export default DataWrapper
