import moment from 'moment'
import React from 'react'
import './App.css'

class UserDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {}
  render() {
    const { user, programs } = this.props
    let tpsTotal = 0
    let tpsOther = {}
    user.trackers.forEach((t) => {
      tpsTotal += t.counting
      if (t.programId) {
        tpsOther[t.programId] += t.counting
      }
    })
    if (user.purchases.length === 0) return <div></div>
    return (
      <div className="userContainer">
        <p>
          <span className={'fnln'}>
            {user.firstname} {user.lastname}
          </span>{' '}
          <span className={'email'}>{this.props.user.email}</span>{' '}
          <span className={'companyname'}>@{user.company.name}</span> #{user.id}
        </p>
        {user.purchases.length === 0 && <div>Aucun programme acheté</div>}
        {user.purchases.map((pur) => {
          let program = programs.find((p) => p.id === pur.programId)
          return (
            <div>
              <p className="progTitle">
                <b>{program.title.replace('$', '')}</b> - Origine : {pur.stripePrice} - Date d'achat :{' '}
                {moment(pur.date).format('DD/MM/YY HH:mm:ss')}
              </p>
              <div className="tableContainer">
                <table>
                  <tr>
                    <th>Ep titre</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      return (
                        <th>
                          {e.order} - {e.title.substr(0, 30)}
                        </th>
                      )
                    })}
                  </tr>
                  <tr>
                    <td>Avancement</td>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)
                      if (!view || (!view.complete && view.currentDuration === 0)) return <td>Not started</td>
                      if (view.complete)
                        return (
                          <td>
                            <div
                              style={{
                                height: 20,
                                backgroundColor: 'green',
                              }}
                            ></div>
                          </td>
                        )
                      let width = Math.round((view.currentDuration * 150) / e.duration)
                      return (
                        <td>
                          <span>
                            {timerFormater(view.currentDuration)} / {timerFormater(e.duration)}{' '}
                          </span>
                          <div
                            style={{
                              height: 6,
                              width,
                              backgroundColor: 'yellow',
                            }}
                          ></div>
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td>Note</td>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view || view.note === -1) return <td>-</td>
                      return <td>{view.note + 1}⭐</td>
                    })}
                  </tr>
                  <tr>
                    <td>Date ep. started</td>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view) return <td>-</td>
                      return <td>{moment(view.createdAt).format('DD/MM/YY HH:mm:ss')}</td>
                    })}
                  </tr>
                  <tr>
                    <td>Date ep. lastView</td>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view) return <td>-</td>
                      return <td>{moment(view.date).format('DD/MM/YY HH:mm:ss')}</td>
                    })}
                  </tr>
                  <tr>
                    <td>Type</td>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view) return <td>-</td>
                      return <td>{view.type === 'video' ? 'Vidéo 🎬' : 'Audio 🎵'}</td>
                    })}
                  </tr>
                </table>
              </div>
            </div>
          )
        })}
        <p>⏳ Temps passé sur l'app (approx): {Math.round((tpsTotal * 1.1) / 2)} minutes</p>
      </div>
    )
  }
}

function timerFormater(time) {
  var minutes = Math.floor(time / 60)
  var seconds = Math.floor(time - minutes * 60)
  return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`
}

export default UserDetails
